<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex
				class="font-level-3-bold my-auto d-flex justify-space-between w-100 d-flex justify-space-between"
			>
				<span class="detail-svg-icon">
					<!--begin::Svg Icon-->
					<inline-svg
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/team-member1.svg')"
						height="20"
						width="20"
						class="mr-2"
					/>
					<!--end::Svg Icon-->
					<template v-if="title">
						{{ title }}
					</template>
					<template v-else>Team Member</template>
				</span>
				<div>
					<v-btn
						@click="openTeamManageDialog"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
						depressed
					>
						<v-icon>mdi-plus</v-icon> Manage</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Image</th>
						<th>User #</th>
						<th>Email</th>
						<th>Display Name</th>
						<th>Role</th>
						<th>Phone Number</th>
						<th>Status</th>
						<th>Created By</th>
					</tr>
				</thead>
				<tbody v-if="teamMemberList.length">
					<tr class="cursor-pointer" v-for="(row, index) in teamMemberList" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>
						<td>
							<ImageTemplate circle style="max-width: 50px" :src="row.user_image"> </ImageTemplate>
						</td>
						<td>
							<Chip :text="row.user_barcode" color="cyan"></Chip>
						</td>
						<td>
							<ShowValue :object="row" object-key="user_email" label="Email"></ShowValue>
						</td>
						<td>
							<ShowValue :object="row" object-key="user_name" label="Name"></ShowValue>
						</td>
						<td>
							<ShowValue :object="row" object-key="user_role" label="Role"></ShowValue>
						</td>
						<td>
							<ShowValue :object="row" object-key="user_phone" label="Phone Number"></ShowValue>
						</td>
						<td>
							<Chip tooltip tooltip-text="Status" :text="row.user_status_value" color="green"></Chip>
						</td>

						<td>
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.addedBy"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.created_at"
								:system-format="row.added_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no team member at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog :dialog="manageDialog" title="Asset Stocks" current v-on:close="manageDialog = false">
			<template v-slot:title> Manage Team Member </template>
			<template v-slot:body>
				<table width="100%" class="detail-table scattered-table">
					<div>
						<v-form v-if="manageDialog" ref="manageTeamFIrn">
							<v-row>
								<v-col md="3" class="my-auto py-0">
									<label :for="`team_member`" class="btx-label mb-1">Team Member</label>
								</v-col>
								<v-col md="9" class="py-0">
									<!-- {{ teamMemberValue }} -->
									<TeamMembers
										hide-detail
										v-model="teamMemberValue"
										:items="manageTeamMemberList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="team_members"
										placeholder="Team Members"
										@remove="remove"
										multiple
										:selectionChip="2"
									/>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</table>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="manageDialog = false"> Close </v-btn>
				<v-btn @click="manageTeamMember" class="white--text" depressed color="green" tile> Save </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ShowValue from "@/view/components/ShowValue.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
import Dialog from "@/view/components/Dialog";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";
import TableDateTime from "@/view/components/TableDateTime";
import { getTeamMemberByProject, manageTeamMember } from "../../core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import TeamMembers from "@/view/components/TeamMembers";

export default {
	name: "team-member-listing",
	title: "Team Member Listing",
	mixins: [ListingMixin],
	components: {
		Dialog,
		ShowValue,
		Chip,
		TableDateTime,
		TeamMembers,
		ImageTemplate,
		// AutoCompleteInput,
	},
	data() {
		return {
			pageLoading: false,
			manageDialog: false,
			teamMember: ["Member 1", "Member 2", "Member 3", "Member 4", "Member 5"],
			payment_detail: {
				date: null,
				documents: [],
				amount_received: null,
				reference: null,
			},
			teamMemberList: [],
			teamMemberValue: [],
			manageTeamMemberList: [],
		};
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openTeamManageDialog() {
			this.manageDialog = true;
			// this.teamMemberValue = [];
		},
		remove(item) {
			this.teamMemberValue = item;
		},

		async getTeamMemberList() {
			const _this = this;
			_this.pageLoading = true;
			try {
				const { team_members } = await getTeamMemberByProject(_this.$route.params.uuid);
				_this.teamMemberList = team_members;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				_this.pageLoading = false;
			} finally {
				_this.pageLoading = false;
			}
		},
		async manageTeamMember() {
			const _this = this;
			_this.pageLoading = true;
			try {
				const params = {
					members: _this.teamMemberValue,
				};
				await manageTeamMember(_this.project.uuid, params);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Team Members has been update." },
				]);
				_this.getTeamMemberList();
				_this.manageDialog = false;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				_this.pageLoading = false;
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	computed: {
		...mapGetters(["localDB"]),
	},
	async mounted() {
		this.internal = true;
		await this.getTeamMemberList();
		this.$store.dispatch(SET_LOCAL_DB).then(() => {
			// this.manageTeamMemberList = this.localDB("customers", []);
			this.manageTeamMemberList = this.localDB("members", []);
		});
		if (this.teamMemberList.length > 0) {
			this.teamMemberValue = this.teamMemberList.map((member) => {
				return member.member;
			});
		}
	},
};
</script>
