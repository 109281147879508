var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"dialog":_vm.dialog,"title":"Invoice","current":"","dialogWidth":600},on:{"close":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Generate Invoice ")]},proxy:true},{key:"body",fn:function(){return [_c('table',{staticClass:"detail-table scattered-table",attrs:{"width":"100%"}},[_c('div',{},[(_vm.dialog)?_c('v-form',{ref:"invoiceForm",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4"},[_vm._v("Title")])]),_c('v-col',{attrs:{"md":"9"}},[_c('TextInput',{class:{
									required: !_vm.invoice.title,
								},attrs:{"hide-details":"","placeholder":"Title","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.invoice.title, 'Title')]},model:{value:(_vm.invoice.title),callback:function ($$v) {_vm.$set(_vm.invoice, "title", $$v)},expression:"invoice.title"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4"},[_vm._v("Amount")])]),_c('v-col',{attrs:{"md":"3"}},[_c('TextInput',{class:{
									required: !_vm.invoice.amount,
								},attrs:{"hide-details":"","type":"number","placeholder":"Amount","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.invoice.amount, 'amount')]},model:{value:(_vm.invoice.amount),callback:function ($$v) {_vm.$set(_vm.invoice, "amount", $$v)},expression:"invoice.amount"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4 text-right"},[_vm._v("Invoice No")])]),_c('v-col',{attrs:{"md":"3"}},[_c('TextInput',{class:{
									required: !_vm.invoice.invoice_no,
								},attrs:{"hide-details":"","type":"number","placeholder":"Invoice No","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"rules":[_vm.vrules.required(_vm.invoice.invoice_no, 'Invoice No')]},model:{value:(_vm.invoice.invoice_no),callback:function ($$v) {_vm.$set(_vm.invoice, "invoice_no", $$v)},expression:"invoice.invoice_no"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4"},[_vm._v("Attachment")])]),_c('v-col',{attrs:{"md":"9"}},[_c('FileUpload',{attrs:{"allowAddMore":false},model:{value:(_vm.invoice.attachment),callback:function ($$v) {_vm.$set(_vm.invoice, "attachment", $$v)},expression:"invoice.attachment"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"btx-label mt-4"},[_vm._v("Date")])]),_c('v-col',{staticClass:"py-0",attrs:{"md":"9"}},[_c('DatePicker',{attrs:{"hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"id":"milestone_date"},model:{value:(_vm.invoice.date),callback:function ($$v) {_vm.$set(_vm.invoice, "date", $$v)},expression:"invoice.date"}})],1)],1)],1):_vm._e()],1)])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"depressed":"","tile":"","disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","color":"blue darken-4","tile":""},on:{"click":_vm.updateOrCreate}},[_vm._v(" Save ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }