<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
				<div class="d-flex align-center">
					<div class="detail-svg-icon d-flex align-center">
						<!--begin::Svg Icon-->
						<inline-svg
							fill="#006fc8"
							:src="$assetURL('media/custom-svg/budget.svg')"
							height="20"
							width="20"
							class="mr-2"
						/>
						<!--end::Svg Icon-->

						<template> Budget </template>
					</div>
					<div class="ml-6 d-flex">
						<div>
							<v-chip class="px-2 pr-2 py-2" color="orange" label outlined
								>Total Budget:

								{{ formatMoney(project.cost) }}
							</v-chip>
						</div>

						<div class="mx-6">
							<v-chip class="px-2 pr-2 py-2" color="blue darken-4" label outlined>
								Total Remaining Budget:
								{{ formatMoney(remainingProjectBudget1) }}
							</v-chip>
						</div>
						<div class="">
							<v-chip class="px-2 pr-2 py-2" color="green" label outlined>
								Total Budget Spend:
								{{ formatMoney(project.total_expense_amount) }}</v-chip
							>
						</div>
					</div>
				</div>
				<div class="d-flex">
					<v-btn @click.stop.prevent="addBudgetRow" color="blue darken-4 white--text" tile>
						<v-icon>mdi-plus</v-icon> Add Budget</v-btn
					>
					<v-btn
						v-if="loadedBudget.length > 0"
						:loading="pageLoading"
						@click.stop.prevent="saveBudget()"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
					>
						Save Budgets</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="taskListing mb-16 project-module-listing1"
			style="max-height: calc(100vh - 350px); min-height: 350px"
		>
			<v-form v-model.trim="formValid" ref="budgetForm" lazy-validation>
				<v-simple-table class="bt-table listing-table table-head-sticky" width="100%">
					<thead>
						<tr>
							<th width="40px">#</th>
							<th width="350px">Name</th>
							<th width="30%">Allocated Amount</th>
							<th width="10%">Spend Price</th>
							<th width="8%">View</th>
							<!-- <th width="180px">Action</th> -->
						</tr>
					</thead>
					<tbody v-if="loadedBudget.length">
						<template v-for="(row, index) in loadedBudget">
							<tr :key="index + 1" class="cursor-pointer">
								<td style="vertical-align: top" class="py-3" for="`name-${index}`">{{ index + 1 }}</td>
								<td>
									<div class="d-flex justify-center align-center">
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon
													class="d-inline mr-1"
													v-if="row.total_budget_expense_amount > row.cost"
													small
													color="red"
													dark
													v-bind="attrs"
													v-on="on"
												>
													mdi-circle
												</v-icon>
											</template>
											<span>Budget Exceeded</span>
										</v-tooltip>
										<TextInput
											hide-details
											hideTopMargin
											:rules="[vrules.required(row.name, `Budget Name ${index + 1}`)]"
											:disabled="pageLoading"
											:loading="pageLoading"
											placeholder="Name"
											v-model="row.name"
											:id="`name-${index}`"
											:class="[!row.name ? 'required' : '', row?.sub_budgets.length ? 'mb-2' : '']"
										></TextInput>
									</div>
									<table width="100%" class="sub-budget">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer ml-auto mt-4"
											style="min-width: 100%"
										>
											<td class="d-flex justify-center align-center">
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															class="d-inline mr-1"
															v-if="subBudget.total_sub_budget_expenses > subBudget.cost"
															small
															color="red"
															dark
															v-bind="attrs"
															v-on="on"
														>
															mdi-circle
														</v-icon>
													</template>
													<span>Budget Exceeded</span>
												</v-tooltip>

												<TextInput
													:rules="[
														vrules.required(
															subBudget.name,
															` Sub Budget  of Budget ${index + 1} Name ${subBudgetIndex + 1}`
														),
													]"
													hide-details
													class=""
													hideTopMargin
													:disabled="pageLoading || subBudget.is_other == 1 ? true : false"
													:loading="pageLoading"
													:id="`subBudget-${index}${subBudgetIndex}`"
													:class="{ required: !subBudget.name }"
													placeholder="Name"
													style="min-width: 90%"
													v-model="subBudget.name"
												>
												</TextInput>
											</td>
										</tr>
									</table>
								</td>
								<td class="pb-3">
									<div class="d-flex align-center">
										<PriceInput
											hide-details
											hideTopMargin
											:disabled="pageLoading"
											:loading="pageLoading"
											id="Cost"
											hideSpinButtons
											type="number"
											placeholder="Cost"
											v-model.number="row.cost"
											@change="budgetOnChange(index, row)"
											@keyup="budgetOnKeyUp($event, index)"
											@keypress="budgetKeyPress($event, index)"
										>
										</PriceInput>

										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<span v-bind="attrs" v-on="on">
													<v-btn
														@click.prevent.stop="createSubBudget(index, false)"
														:disabled="row.disable_child_creation == 1 ? true : false"
														color="blue darken-4 white--text "
														class="ml-2"
														:dark="row.disable_child_creation == 0 ? true : false"
														fab
														x-small
													>
														<v-icon>mdi-plus</v-icon></v-btn
													>
												</span>
											</template>
											<span>Create Sub Budget</span>
										</v-tooltip>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<span v-bind="attrs" v-on="on">
													<v-btn
														@click="deleteBudget(index, row)"
														class="red white--text ml-2"
														:disabled="row.disable_delete == 1 ? true : false"
														fab
														:dark="row.disable_delete == 0 ? true : false"
														x-small
													>
														<v-icon color="white">mdi-delete</v-icon>
													</v-btn>
												</span>
											</template>
											<span>Delete Budget</span>
										</v-tooltip>
									</div>
									<table width="100%" class="sub-budget sub-budget-1">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer mt-4"
											style="min-width: 100%"
										>
											<td class="d-flex align-center">
												<PriceInput
													hide-details
													hideSpinButtons
													:disabled="pageLoading || subBudget.is_other == 1 ? true : false"
													hideTopMargin
													type="number"
													:loading="pageLoading"
													id="cost"
													placeholder="Cost"
													v-model.number="subBudget.cost"
													@keyup="subBudgetsChange1(row, index, subBudgetIndex)"
													@keypress="subBudgetKeyPress($event, index, subBudgetIndex)"
												>
												</PriceInput>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															@click="deleteSubBudget(index, row, subBudgetIndex, subBudget)"
															class="red white--text mt-2 ml-2"
															dense
															v-bind="attrs"
															:class="{ 'visibility-none': subBudget.is_other == 1 ? true : false }"
															v-on="on"
															:disabled="
																subBudget?.expenses.length || subBudget?.purchase_orders.length ? true : false
															"
															style="width: 25px; height: 25px"
															fab
															x-small
														>
															<v-icon color="white" x-small>mdi-delete</v-icon>
														</v-btn>
													</template>
													<span>Delete Sub Budget</span>
												</v-tooltip>
											</td>
										</tr>
									</table>
								</td>
								<td :class="{ 'custom-vertical-align-bsottom': row.sub_budgets.length }">
									<div v-if="!row.sub_budgets.length" class="ml-2">
										<span v-if="row.total_budget_expense_amount && !row.sub_budgets.length">
											<v-chip color="blue darken-4" outlined label v-if="row.total_budget_expense_amount">{{
												formatMoney(row.total_budget_expense_amount)
											}}</v-chip></span
										>
										<v-chip color="blue darken-4" outlined label v-else>{{ formatMoney(0) }}</v-chip>
									</div>
									<div style="height: 3.5rem" v-if="row.sub_budgets.length"></div>
									<table>
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer"
											style="height: 3rem"
										>
											<td class="">
												<v-chip
													small
													color="blue darken-4"
													outlined
													label
													v-if="subBudget.total_sub_budget_expenses"
													>{{ formatMoney(subBudget.total_sub_budget_expenses) }}</v-chip
												>
												<v-chip small color="blue darken-2" outlined label v-else>{{ formatMoney(0) }}</v-chip>
											</td>
										</tr>
										<tr>
											<td class="" style="height: 36px:"></td>
										</tr>
									</table>
								</td>
								<td :class="{ 'custom-vertical-align-bosttom': row.sub_budgets.length }">
									<div>
										<v-btn
											v-if="row.total_budget_expense_amount && !row.sub_budgets.length"
											class="blue darken-4 white--text"
											dense
											small
											@click="getExpenseById(row.id)"
										>
											View
										</v-btn>
									</div>
									<div style="height: 3.8rem" v-if="row.sub_budgets.length"></div>
									<table class="d-flex flex-column">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											style="height: 3rem"
											:key="subBudgetIndex + 4"
										>
											<td>
												<v-btn
													v-if="subBudget.expenses.length || subBudget.purchase_orders.length"
													class="block ml-2 blue darken-4 white--text cursor-pointer"
													dense
													style="width: 54px"
													x-small
													@click="getExpenseById(row.id, subBudget.id)"
												>
													View
												</v-btn>
											</td>
										</tr>
									</table>
								</td>
								<!-- <td class="py-3" style="vertical-align: top">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<v-btn
													@click.prevent.stop="createSubBudget(index, false)"
													:disabled="row.disable_child_creation == 1 ? true : false"
													color="blue darken-4 white--text"
													:dark="row.disable_child_creation == 0 ? true : false"
													fab
													x-small
												>
													<v-icon>mdi-plus</v-icon></v-btn
												>
											</span>
										</template>
										<span>Create Sub Budget</span>
									</v-tooltip>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<v-btn
													@click="deleteBudget(index, row)"
													class="red white--text ml-4"
													:disabled="row.disable_delete == 1 ? true : false"
													fab
													:dark="row.disable_delete == 0 ? true : false"
													x-small
												>
													<v-icon color="white">mdi-delete</v-icon>
												</v-btn>
											</span>
										</template>
										<span>Delete Budget</span>
									</v-tooltip>
									<table width="100%" class="sub-budget-1">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer mt-4"
										>
											<td class="d-flex align-center" style="margin-left: -0.5rem">
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															@click="deleteSubBudget(index, row, subBudgetIndex, subBudget)"
															class="red white--text mt-2"
															dense
															v-bind="attrs"
															v-on="on"
															:disabled="
																subBudget?.expenses.length || subBudget?.purchase_orders.length ? true : false
															"
															style="width: 25px; height: 25px"
															fab
															x-small
														>
															<v-icon color="white" x-small>mdi-delete</v-icon>
														</v-btn>
													</template>
													<span>Delete Sub Budget</span>
												</v-tooltip>
											</td>
										</tr>
									</table>
								</td> -->
							</tr>
						</template>
					</tbody></v-simple-table
				>
			</v-form>
		</div>

		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<DeleteTemplate
			type="Budget"
			v-on:success="deleteConfirmBudget"
			:deleteByUrl="false"
			v-on:close="deleteBudgetDialog = false"
			:delete-dialog="deleteBudgetDialog"
			v-if="deleteBudgetDialog"
			:deleteText="singleBudget?.name"
		>
		</DeleteTemplate>
		<DeleteTemplate
			type="Sub Budget"
			v-on:success="deleteConfirmSubBudget"
			:deleteByUrl="false"
			v-on:close="deleteSubBudgetDialog = false"
			v-if="deleteSubBudgetDialog"
			:delete-dialog="deleteSubBudgetDialog"
			:deleteText="singleSubBudget?.name"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
// import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
// import Status from "@/view/components/Status";
// import ShowPrice from "@/view/components/ShowPrice";
// import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
// import Chip from "@/view/components/Chip";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import {
	getBudgetByProject,
	createBudget,
	deleteBudget,
	deleteSubBudget,
	/* getExpenseByBudget */
} from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
	name: "budget-listing",
	title: "Budget Listing",
	components: {
		// ShowTextAreaValue,
		// ShowPrice,
		// Status,
		TextInput,
		// Chip,
		// Dialog,
		DeleteTemplate,
		PriceInput,
	},
	data() {
		return {
			totalBudget: null,
			pageLoading: false,
			// budgetSNO: null,
			singleBudget: null,
			singleSubBudget: null,
			// project_budget: 3000,
			project_remanding: 0,
			uuid: null,
			subBudgetUUID: null,
			budgetUUID: null,
			// isApproveDialogOpen: false,
			// isMilestoneDetailDialogOpen: false,
			// isCreateMilestoneDialog: false,
			formValid: true,
			budgets: [],
			deleteDialog: false,
			deleteSubBudgetDialog: false,
			deleteBudgetDialog: false,
			// expenseDetailDialog: false,
			budget: {
				name: null,
				cost: null,
			},
			loadedBudget: [],
			budget_spend: null,
			spendList: [],
			purchaseOrderExpense: [],
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
		internal: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		addBudgetRow() {
			const budget = {
				name: null,
				cost: 0,
				// editable: true,
				sub_budgets: [],
			};
			this.loadedBudget = [...this.loadedBudget, budget];
		},
		async getExpenseById(id, subBudget) {
			this.$router.push({
				name: `project-budget`,
				params: { id: this.$route.params.uuid, budget_id: id },
				query: { sub_budget: subBudget },
			});
		},
		createSubBudget(index) {
			const sub_budgets = {
				name: null,
				is_other: 0,
				cost: 0,
				purchase_orders: [],
				expenses: [],
				// editable: true,
			};
			if (this.loadedBudget[index].sub_budgets) {
				// this.loadedBudget[index].sub_budgets = [...this.loadedBudget[index].sub_budgets, sub_budgets];
				if (this.loadedBudget[index].sub_budgets.length == 0) {
					this.loadedBudget[index].sub_budgets.push({
						name: "other",
						cost: this.loadedBudget[index]["cost"],
						is_other: 1,
						purchase_orders: [],
						expenses: [],
					});
				}
				this.loadedBudget[index].sub_budgets.unshift(sub_budgets);
			}
			// this.loadedBudget[index].sub_budgets.push({
			// 	name: null,
			// 	cost: null,
			// 	is_other: 0,
			// 	purchase_orders: [],
			// 	expenses: [],
			// });
			// console.log(this.loadedBudget[index]["cost"]);
			// this.loadedBudget[index].sub_budgets.push({
			// 	name: "other",
			// 	cost: this.loadedBudget[index]["cost"],
			// 	is_other: 1,
			// 	purchase_orders: [],
			// 	expenses: [],
			// });
			// }
			// console.log(this.loadedBudget[index].sub_budgets);
		},

		deleteBudget(index, budget) {
			this.singleBudget = budget;
			if (budget?.uuid) {
				this.deleteBudgetDialog = true;
				this.budgetUUID = budget.uuid;
			} else {
				this.loadedBudget.splice(index, 1);
			}
		},
		async deleteConfirmBudget() {
			const _this = this;
			_this.pageLoading = true;
			try {
				await deleteBudget(_this.budgetUUID);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success !Budget has been deleted.` },
				]);
				_this.budgetUUID = null;
				_this.deleteBudgetDialog = false;
				_this.initBudgets();
			} catch (error) {
				// _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				if (error?.data?.message) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
				} else {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				_this.pageLoading = false;
			}
		},
		async deleteConfirmSubBudget() {
			const _this = this;
			_this.pageLoading = true;
			try {
				await deleteSubBudget(_this.subBudgetUUID, _this.budgetUUID);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Sub Budget has been deleted.` },
				]);
				_this.subBudgetUUID = null;
				_this.budgetUUID = null;
				_this.initBudgets();
				_this.deleteSubBudgetDialog = false;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async deleteSubBudget(budgetIndex, budget, subBudgetIndex) {
			const _this = this;
			// if (subBudget?.uuid) {
			// 	this.singleSubBudget = subBudget;
			// 	_this.deleteSubBudgetDialog = true;
			// 	_this.subBudgetUUID = subBudget.uuid;
			// 	_this.budgetUUID = budget.uuid;
			// } else {
			_this.loadedBudget[budgetIndex]["sub_budgets"].splice(subBudgetIndex, 1);
			// }
			this.changeOtherSubBudget(budgetIndex, budget);
		},
		async saveBudget() {
			const _this = this;
			// const formErrors = _this.validateForm(_this.$refs.budgetForm);

			// if (formErrors.length) {
			// 	_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
			// 	return false;
			// }
			// if (!_this.$refs.budgetForm.validate()) {
			// 	return false;
			// }

			for (let index = 0; index < this.loadedBudget.length; index++) {
				if (!this.loadedBudget[index].name) {
					const focus_element = `name-${index}`;
					document.getElementById(focus_element).focus();
					_this.$store.commit(
						SET_ERROR,
						_this.errors.concat([{ model: true, message: "Budget Name is required" }])
					);
					return false;
				}
				for (
					let subBudgetIndex = 0;
					subBudgetIndex < this.loadedBudget[index].sub_budgets.length;
					subBudgetIndex++
				) {
					if (!this.loadedBudget[index].sub_budgets[subBudgetIndex].name) {
						const focus_element = `subBudget-${index}${subBudgetIndex}`;
						document.getElementById(focus_element).focus();
						document.getElementById(focus_element).focus();
						_this.$store.commit(
							SET_ERROR,
							_this.errors.concat([{ model: true, message: "Sub Budget Name is required" }])
						);
						return false;
					}
				}
			}

			// this.loadedBudget.forEach((ele, index) => {
			// 	let total = 0;
			// 	this.loadedBudget[index].sub_budgets.forEach((ele) => {
			// 		total = total + ele.cost;
			// 	});
			// });
			this.pageLoading = true;
			try {
				await createBudget(this.project.id, this.loadedBudget);
				this.initBudgets();
				await this.initBudgets();
			} catch (error) {
				// this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				if (error?.data?.message) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error.data?.message }]);
				} else {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
				this.pageLoading = false;
			}
		},
		async initBudgets() {
			try {
				this.pageLoading = true;
				let { budgets } = await getBudgetByProject(this.$route.params.uuid);
				this.loadedBudget = budgets;
			} catch (error) {
				// this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				if (error?.data?.message) {
					this.$store.commit(SET_ERROR, [{ model: true, message: error.data.message }]);
				} else {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				}
			} finally {
				this.pageLoading = false;
			}
		},

		totalBudgetSpend() {
			if (this.loadedBudget) {
				this.budget_spend = this.loadedBudget.reduce((total, ele) => {
					return total + ele.cost;
				}, 0);
			} else {
				this.budget_spend = 0;
			}
		},
		subBudgetsChange1(row, budget_index, subBudgetIndex) {
			let subBudgets = this.loadedBudget[budget_index].sub_budgets;
			let subBudgetTotal = 0;
			subBudgets.forEach((subBudget) => {
				if (subBudget["is_other"] == 0 && subBudget.cost >= 0) {
					subBudgetTotal = subBudgetTotal + Number(subBudget.cost);
				}
			});
			if (row.cost < subBudgetTotal) {
				row.sub_budgets[subBudgetIndex].cost = 0;
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Total Sub Budget can not be greater than budget value" },
				]);
			}
			let newSubBudgetTotal = 0;
			subBudgets.forEach((subBudget) => {
				if (subBudget["is_other"] == 0) {
					newSubBudgetTotal = newSubBudgetTotal + Number(subBudget.cost);
				}
			});
			if (
				row["cost"] - newSubBudgetTotal >= 0 &&
				row.sub_budgets[row.sub_budgets.length - 1]["is_other"]
			) {
				const remainingOtherBudget = row["cost"] - newSubBudgetTotal;
				row.sub_budgets[row.sub_budgets.length - 1]["cost"] = remainingOtherBudget.toFixed(2);
			}

			// console.log("row.cost",row.cost);
			// console.log("total",total);
			// if(total>row.cost)
			// {
			// 	row.sub_budgets[subBudgetIndex].cost=total-row.cost
			// }
			// let totalAllBudget = 0;
			// subBudgets.forEach((subBudget) => {
			// 	totalAllBudget = totalAllBudget + Math.abs(subBudget.cost);
			// });
			// console.log(row.sub_budgets[row.sub_budgets.length - 1].cost);
			// if (
			// 	row.sub_budgets[row.sub_budgets.length - 1]["is_other"] &&
			// 	row.sub_budgets[row.sub_budgets.length - 1].cost > 0
			// ) {
			// 	let totalAllBudget = 0;
			// 	this.loadedBudget[budget_index].sub_budgets.forEach((subBudget) => {
			// 		totalAllBudget = totalAllBudget + Math.abs(Number(subBudget.cost));
			// 	});
			// 	console.log(row.cost - (totalAllBudget - row.sub_budgets[subBudgetIndex].cost));
			// 	// row.sub_budgets[row.sub_budgets.length - 1]["cost"] = row["cost"] - total;
			// 	// }
			// 	// row.sub_budgets[subBudgetIndex].cost =
			// 	// 	row.cost - (totalAllBudget - row.sub_budgets[subBudgetIndex].cost);
			// 	// console.log("totalAllBudget", row.cost - (totalAllBudget ));
			// 	// console.log("totalAllBudget", totalAllBudget);
			// 	// if (row.cost - totalAllBudget) {
			// 	// 	row.sub_budgets[subBudgetIndex]["cost"] =
			// 	// 		row.cost - (totalAllBudget - row.sub_budgets[subBudgetIndex]["cost"]);
			// }
			// if (row.cost - totalAllBudget < 0) {
			// console.log("row.cost", row.cost);
			// console.log(
			// 	"totalAllBudget - row.sub_budgets[subBudgetIndex]['cost']",
			// 	totalAllBudget - row.sub_budgets[subBudgetIndex]["cost"]
			// );
			// row.sub_budgets[subBudgetIndex]["cost"] =
			// 	row.cost - (totalAllBudget - row.sub_budgets[subBudgetIndex]["cost"]);
			// }
			// if (row.cost < totalAllBudget) {
			// 	row.sub_budgets[subBudgetIndex]["cost"] =
			// 		row.cost - (totalAllBudget - row.sub_budgets[subBudgetIndex]["cost"]);
			// }
			// if (this.loadedBudget[budget_index].cost - total > 0) {
			// 	this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost =
			// 		this.loadedBudget[budget_index].cost -
			// 		(total - this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost);
			// }
			// }
			// let budgets = cloneDeep(this.loadedBudget[budget_index].sub_budgets);
			// const totalOtherAllocatedBudget = budgets.reduce((total, ele) => {
			// 	if (!ele.is_other) {
			// 		return total + Number(ele.cost);
			// 	}
			// }, 0);
			// // console.log(this.loadedBudget[budget_index].cost - totalOtherAllocatedBudget);
			// if (this.loadedBudget[budget_index].cost - totalOtherAllocatedBudget < 0) {
			// 	console.log(
			// 		"totalOtherAllocatedBudget",
			// 		this.loadedBudget[budget_index].sub_budgets[subBudgetIndex]
			// 	);
			// 	this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost =
			// 		this.loadedBudget[budget_index].cost -
			// 		(totalOtherAllocatedBudget - this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost);
			// }
			// console.log(this.loadedBudget[budget_index].cost, totalOtherAllocatedBudget);
			// let subBudget = cloneDeep(this.loadedBudget[budget_index].sub_budgets);
			// if (subBudget[row.sub_budgets.length - 1]["is_other"] === 1) {
			// 	subBudget.pop();
			// }
			// const total = subBudget.reduce((total, ele) => {
			// 	return total + Number(ele.cost);
			// }, 0);
			// if (row.sub_budgets[row.sub_budgets.length - 1]["is_other"]) {
			// 	if (this.loadedBudget[budget_index].cost - total > 0) {
			// 		row.sub_budgets[row.sub_budgets.length - 1]["cost"] = row["cost"] - total;
			// 	} else {
			// 		this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost =
			// 			this.loadedBudget[budget_index].cost -
			// 			(total -
			// 				this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost);
			// 	}
			// }
		},
		subBudgetKeyPress($event, budget_index, subBudgetIndex) {
			if (String(this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost).includes(".")) {
				let subBudgets = String(this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost).split(
					"."
				);
				if (subBudgets[1].length > 1) {
					$event.preventDefault();
				}
			}

			// let currentSubBudget = Number(String(subBudgets[subBudgetIndex].cost) + String($event.key));
			// subBudgets[subBudgetIndex].cost = currentSubBudget;
			// let totalSubBudgetCost = 0;
			// subBudgets.forEach((ele) => {
			// 	if (ele.is_other == 0) {
			// 		totalSubBudgetCost = ele.cost + totalSubBudgetCost;
			// 	}
			// });
			// if (totalSubBudgetCost > row.cost) {
			// 	$event.preventDefault();
			// 	this.$store.commit(SET_ERROR, [
			// 		{ model: true, message: "Total Sub Budget can not be greater than budget value" },
			// 	]);
			// }
		},
		subBudgetPaste($event, budget_index, subBudgetIndex, row) {
			let clipboardData, pastedData;
			clipboardData = $event.clipboardData || window.clipboardData;
			pastedData = clipboardData.getData("text/plain");
			let subBudgets = cloneDeep(this.loadedBudget[budget_index].sub_budgets);
			let currentSubBudget = Number(String(subBudgets[subBudgetIndex].cost) + String(pastedData));
			subBudgets[subBudgetIndex].cost = currentSubBudget;
			let totalSubBudgetCost = 0;
			subBudgets.forEach((ele) => {
				totalSubBudgetCost = ele.cost + totalSubBudgetCost;
			});

			if (totalSubBudgetCost > row.cost) {
				$event.stopPropagation();
				$event.preventDefault();
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Total Sub Budget can not be greater than budget value" },
				]);
				setTimeout(() => {
					this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost = 0;
				}, 0);
			}
		},
		budgetKeyPress($event, index) {
			if (String(this.loadedBudget[index].cost).includes(".")) {
				let decimalNumber = String(this.loadedBudget[index].cost).split(".");
				if (decimalNumber[1].length > 1) {
					$event.preventDefault();
				}
			}
		},
		budgetPaste($event, index) {
			let clipboardData, pastedData;
			clipboardData = $event.clipboardData || window.clipboardData;
			pastedData = clipboardData.getData("text/plain");
			let budget = cloneDeep(this.loadedBudget[index]);
			let budgets = cloneDeep(this.loadedBudget);
			this.loadedBudget[index].sub_budgets.forEach((subBudget) => {
				subBudget.cost = null;
			});
			let totalBudget1 = Number(String(budget.cost) + String(pastedData));
			budgets[index].cost = totalBudget1;
			let totalBudgetCost = 0;
			budgets.forEach((ele) => {
				totalBudgetCost = ele.cost + totalBudgetCost;
			});
			if (totalBudgetCost > this.project.cost) {
				$event.stopPropagation();
				$event.preventDefault();
				setTimeout(() => {
					this.loadedBudget[index].cost = 0;
				}, 100);
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Total Allocated amount can not be greater than project budget" },
				]);
			}
		},
		changeOtherSubBudget(index, row) {
			let subBudget = cloneDeep(this.loadedBudget[index])["sub_budgets"];
			if (
				subBudget[row.sub_budgets.length - 1] &&
				subBudget[row.sub_budgets.length - 1]["is_other"] === 1
			) {
				subBudget.pop();
			}
			const subBudgetTotal = subBudget.reduce((total, ele) => {
				return total + Number(ele.cost);
			}, 0);
			const subBudget1 = this.loadedBudget[index]["sub_budgets"];
			if (subBudget1[subBudget1.length - 1]?.is_other) {
				subBudget1[subBudget1.length - 1]["cost"] = row["cost"] - subBudgetTotal;
			}
		},
		budgetOnChange(index, row) {
			let total = 0;
			this.loadedBudget[index].sub_budgets.forEach((ele) => {
				total = total + ele.cost;
			});
			if (this.loadedBudget[index].cost < total) {
				this.loadedBudget[index].cost = total;
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Budget Can not be less than sub budget total!" },
				]);
			}
			this.changeOtherSubBudget(index, row);
		},
		budgetOnKeyUp(_, index) {
			// const remainingBudget = this.project.cost - this.remainingProjectBudget;
			// console.log(remainingBudget);
			// let total = 0;
			// let budgets = cloneDeep(this.loadedBudget);
			// const otherTotalAllocatedBudget = this.loadedBudget.forEach((ele, eleIndex) => {
			// 	if (index != eleIndex) {
			// 		total = ele.cost + total;
			// 	}
			// 	return total;
			// });
			let budgets = cloneDeep(this.loadedBudget);
			const newFilterBudger = budgets.filter((ele, eleIndex) => {
				if (index != eleIndex) {
					return ele;
				}
			});
			const totalOtherAllocatedBudget = newFilterBudger.reduce((total, ele) => {
				return total + ele.cost;
			}, 0);
			if (this.project.cost - this.remainingProjectBudget < 0) {
				this.loadedBudget[index].cost = this.project.cost - totalOtherAllocatedBudget;
			}
			// console.log(this.project.cost - this.remainingProjectBudget);
			// console.log(budgets);
			// let budget1 = budgets.splice(index, 1);
			// console.log(budgets.splice(index, 1));
			// console.log("otherTotalAllocatedBudget",otherTotalAllocatedBudget);
			// const remainingBudget = this.project.cost - otherTotalAllocatedBudget;
			// console.log("remainingBudget",remainingBudget);
			// if (remainingBudget < 0) {
			// 	this.loadedBudget[index].cost = remainingBudget;
			// }

			// console.log(otherTotalAllocatedBudget);
			// let budget = cloneDeep(this.loadedBudget[index]);
			// let budgets = cloneDeep(this.loadedBudget);
			// let totalBudget1 = Number(String(budget.cost) + String($event.key));
			// budgets[index].cost = totalBudget1;
			// let totalBudgetCost = 0;
			// budgets.forEach((ele) => {
			// 	totalBudgetCost = ele.cost + totalBudgetCost;
			// });
			// if (totalBudgetCost > this.project.cost) {
			// 	$event.preventDefault();
			// 	this.$store.commit(SET_ERROR, [
			// 		{ model: true, message: "Total Allocated amount can not be greater than project budget" },
			// 	]);
			// 	setTimeout(() => {
			// 		// this.loadedBudget[index].cost = 0;
			// 	}, 100);
			// }
		},
		// budgetOnKeyUp($event, index) {
		// 	let budget = cloneDeep(this.loadedBudget[index]);
		// 	let budgets = cloneDeep(this.loadedBudget);

		// 	console.log($event);
		// 	let totalBudget1 = 0;
		// 	if($event.charCode == 46)
		// 	{
		// 		totalBudget1 = String(budget.cost) + String('.00');
		// 	}
		// 	else if()
		// 	{
		// 		totalBudget1 = Number(budget.cost) + Number($event.key);
		// 	}
		// 	else
		// 	{
		// 		totalBudget1 = Number(String(budget.cost) + String($event.key));
		// 	}
		// 	console.log("cost",String(budget.cost));
		// 	console.log("totalBudget1",totalBudget1);
		// 	budgets[index].cost = totalBudget1;
		// 	let totalBudgetCost = 0;
		// 	budgets.forEach((ele) => {
		// 		totalBudgetCost = ele.cost + totalBudgetCost;
		// 	});
		// 	console.log("totalBudgetCost",this.project.cost);
		// 	if (Number(totalBudgetCost) > Number(this.project.cost)) {
		// 		$event.preventDefault();
		// 		this.$store.commit(SET_ERROR, [
		// 			{ model: true, message: "Total Allocated amount can not be greater than project budget" },
		// 		]);
		// 		setTimeout(() => {
		// 			// this.loadedBudget[index].cost = 0;
		// 		}, 100);
		// 	}
		// },
	},
	watch: {
		loadedBudget: {
			handler() {
				// this.remainingProjectBudget();
				this.totalBudgetSpend();
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters(["errors"]),
		remainingProjectBudget() {
			let total = 0;
			this.loadedBudget.forEach((ele) => {
				if (ele.cost) {
					total = total + ele.cost;
				}
			});
			return total;
		},
		remainingProjectBudget1() {
			const remainingBudget = this.project.cost - this.remainingProjectBudget;
			return remainingBudget.toFixed(2);
		},
	},
	async mounted() {
		// this.internal = true;
		this.totalBudget = this.project.cost;
		await this.initBudgets();
		// this.remainingProjectBudget();
		this.totalBudgetSpend();
	},
};
</script>
<style scoped>
.cursor-not-allowed {
	cursor: not-allowed;
}
</style>
