<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<span class="detail-svg-icon mr-2 d-flex align-center">
					<!--begin::Svg Icon-->
					<inline-svg
						fill="#006fc8"
						:src="$assetURL('media/custom-svg/quotation3.svg')"
						height="20"
						width="20"
					/>
					<!--end::Svg Icon-->
					<template>
						<span class="ml-2">Quotation </span>
						<div>
							<v-chip
								v-if="quotationsList.length"
								class="px-2 pr-2 ml-4 mr-6 py-2"
								color="green"
								label
								outlined
								>Total Quotation: {{ quotationsList.length }}
							</v-chip>
						</div>
						<div>
							<v-chip class="px-2 pr-2 py-2" color="green" label outlined
								>Quotation Approved:
								{{ quotation_analysis?.quotation_approved_count }}
							</v-chip>
						</div>
						<div class="mx-6">
							<v-chip class="px-2 pr-2 py-2" color="blue darken-4" label outlined>
								Quotation Draft:
								<span>{{ quotation_analysis?.quotation_draft_count }}</span></v-chip
							>
						</div>
						<div class="">
							<!-- {{quotation.analysis}} -->
							<v-chip class="px-2 pr-2 py-2" color="red" label outlined>
								Quotation Rejected:
								<span>{{ quotation_analysis?.quotation_rejected_count }}</span></v-chip
							>
						</div>
					</template>
				</span>
				<div>
					<v-btn
						@click="goToQuotationCreate(false)"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
						v-if="getPermission('quotation::create') && false"
						depressed
					>
						<v-icon>mdi-plus</v-icon> Create</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 400px); min-height: 350px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<!-- {{ quotationsList }} -->
				<thead>
					<tr>
						<th width="10px">Quotation #</th>
						<th>Customer</th>
						<th>Customer Address</th>
						<th>Service Address</th>
						<!-- <th>Property</th> -->
						<!-- <th>Project</th> -->
						<!-- <th>Rate Opportunity</th> -->
						<th>Title</th>
						<th>References</th>
						<th>Prepared By</th>
						<!-- <th>Action</th> -->
					</tr>
				</thead>
				<tbody v-if="quotationsList.length">
					<tr
						class="task_row cursor-pointer"
						v-for="(row, index) in quotationsList"
						:key="index + 1"
						@click="goToQuotationDetail(row)"
					>
						<td width="10px">
							<Chip v-if="row" tooltip tooltip-text="Quotation #" :text="row.barcode" color="cyan"></Chip>
							<div>
								<Chip
									:color="getStatusColor(row.status_value)"
									tooltip
									class="mt-1"
									tooltip-text="Quotation #"
									:text="row.status_value"
								></Chip>
							</div>
						</td>

						<td>
							<Chip
								v-if="row.customer_barcode"
								tooltip
								tooltip-text="customer #"
								:text="row.customer_barcode"
								color="cyan"
							></Chip>
							<em v-else class="text-muted">No Customer</em>
						</td>
						<td>
							<div class="m-0" v-if="row.customer_default_address">
								<div class="d-flex m-0">
									<v-icon left small>mdi-account</v-icon>
									<ShowValue
										:object="row.customer_default_address"
										object-key="display_name"
										label="customer_default_address"
									></ShowValue>
								</div>
								<div class="d-flex m-0">
									<v-icon left small>mdi-phone</v-icon>
									<ShowValue
										:object="row.customer_default_address"
										object-key="phone_number"
										label="customer default address"
									></ShowValue>
								</div>

								<div class="m-0">
									<v-icon left small>mdi-email</v-icon>
									<ShowValue :object="row.customer_default_address" object-key="email" label="email">
									</ShowValue>
								</div>
								<div class="d-flex align-start">
									<v-icon class="mt-2" left small>mdi-map-marker-outline</v-icon>
									<div>
										<p class="m-0" v-if="row && row.customer_default_address.address_line_1">
											<ShowValue
												:object="row.customer_default_address"
												object-key="address_line_1"
												label="address line 1"
											>
											</ShowValue>
										</p>
										<p class="m-0" v-if="row && row.customer_default_address.address_line_2">
											<ShowValue
												:object="row.customer_default_address"
												object-key="address_line_2"
												label="address line 2"
											>
											</ShowValue>
										</p>
										<p class="m-0" v-if="row && row.customer_default_address.unit_number">
											<ShowValue :object="row.customer_default_address" object-key="unit_number"> </ShowValue>
										</p>
										<p class="m-0">
											<template v-if="row && row.customer_default_address.address_country">
												<ShowValue
													:object="row.customer_default_address"
													object-key="address_country"
													label="country"
												>
												</ShowValue>
											</template>
											<template class="m-0" v-if="row && row.customer_default_address.address_postal_code">
												&nbsp;<ShowValue
													:object="row.customer_default_address"
													object-key="address_postal_code"
													label="postal code"
												></ShowValue>
											</template>
										</p>
									</div>
								</div>
							</div>
						</td>
						<td :style="row.shipping_address_name ? 'vertical-align : top' : ' vertical-align: center'">
							<div class="m-0" v-if="row.shipping_address_name">
								<div class="d-flex">
									<v-icon left small>mdi-account</v-icon>
									<ShowValue :object="row" object-key="shipping_address_name" label="Shipping Address">
									</ShowValue>
								</div>

								<div class="d-flex">
									<v-icon left small>mdi-phone</v-icon>
									<ShowValue
										:object="row"
										object-key="shipping_address_phone"
										label="Shipping Address Phone"
									></ShowValue>
								</div>

								<div class="m-0">
									<v-icon left small>mdi-email</v-icon>
									<ShowValue
										:object="row"
										object-key="shipping_address_email"
										label="Shipping Address Email"
									></ShowValue>
								</div>
								<div class="d-flex align-start">
									<v-icon class="mt-2" left small>mdi-map-marker-outline</v-icon>
									<div>
										<p class="m-0" v-if="row && row.shipping_address_line_1">
											<ShowValue
												:object="row"
												object-key="shipping_address_line_1"
												label="Shipping Address line 1"
											>
											</ShowValue>
										</p>
										<p class="m-0" v-if="row && row.shipping_address_line_2">
											<ShowValue
												:object="row"
												object-key="shipping_address_line_2"
												label="Shipping Address line 2"
											>
											</ShowValue>
										</p>
										<p class="m-0">
											<template v-if="row && row.shipping_address_country">
												<ShowValue :object="row" object-key="shipping_address_country" label="country">
												</ShowValue>
											</template>
											<template class="m-0" v-if="row && row.customer_default_address.address_postal_code">
												&nbsp;<ShowValue
													:object="row.customer_default_address"
													object-key="address_postal_code"
													label="postal code"
												></ShowValue>
											</template>
										</p>
									</div>
								</div>
							</div>
							<div v-else>
								<em class="text-muted fw-400">No Service Address</em>
							</div>
						</td>
						<!-- <td class="text-capitalize truncate-text">
							<ShowValue :object="row" object-key="property" label="row.property"></ShowValue>
						</td> -->
						<!-- <td>
							<Chip tooltip tooltip-text="project #" :text="row.project" color="cyan"></Chip>
						</td> -->
						<!-- <td>
							<v-rating
								v-model="row.rating"
								v-if="row.rating > 0"
								background-color="gray"
								color="orange accent-4"
								dense
								half-increments
								readonly
								hover
								size="24"
							></v-rating>
							<em v-else class="text-muted">No rating opportunity</em>
						</td> -->
						<td>
							<span class="line-clamp-2" :style="`min-width:150px`">
								<ShowTextAreaValue :object="row" object-key="title" label="Title"></ShowTextAreaValue>
							</span>
						</td>

						<td width="10px">
							<Chip
								v-if="row.reference"
								tooltip
								tooltip-text="Reference #"
								:text="row.reference"
								color="cyan"
							></Chip>
							<em v-else class="text-muted">No Reference</em>
						</td>

						<td>
							<ShowValue :object="row" object-key="prepared_by" label="Prepared By"></ShowValue>
						</td>
						<!-- <td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										@click.prevent.stop="openDeleteConfirmationDialog(row)"
										class="mx-2"
										color="red lighten-1"
										v-bind="attrs"
										v-on="on"
										dark
										fab
										x-small
									>
										<v-icon small dark> mdi-trash-can-outline</v-icon>
									</v-btn>
								</template>
								<span>Delete Quotation</span>
							</v-tooltip>
						</td> -->
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no quotation at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>

		<DeleteTemplate
			v-if="deleteDialog"
			type="Quotation"
			v-on:success="deleteQuotation"
			v-on:close="(deleteDialog = false), (uuid = null)"
			:delete-dialog="deleteDialog"
			:deleteText="singleQuotation.title"
			:deleteByUrl="false"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import DeleteTemplate from "@/view/components/DeleteTemplate.vue";
import Chip from "@/view/components/Chip";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { getQuotationByProject, deleteQuotation } from "@/core/lib/contract.lib";

export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		ShowValue,
		Chip,
		ShowTextAreaValue,
		DeleteTemplate,
	},
	data() {
		return {
			deleteDialog: false,
			pageLoading: false,
			quotation_analysis: null,
			uuid: null,
			singleQuotation: null,
			quotationsList: [],
		};
	},
	props: {
		relationTypeId: {
			type: Number,
			default: 0,
		},
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		openDeleteConfirmationDialog(row) {
			this.deleteDialog = true;
			this.singleQuotation = row;
			this.uuid = row.uuid;
		},
		async deleteQuotation() {
			this.deleteDialog = false;
			try {
				this.pageLoading = true;
				await deleteQuotation(this.uuid);
				this.uuid = null;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Quotation has been deleted.` },
				]);
				await this.getQuotationList();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},

		goToQuotationCreate() {
			this.$router
				.push({
					name: "quotation-create",
					query: { project: this.$props.project.id, customer: this.$props.project.contact },
				})
				.catch(() => {});
		},

		goToQuotationDetail(quotation) {
			this.$router
				.push({
					name: "quotation-detail",
					params: { uuid: quotation.uuid },
				})
				.catch(() => {});
		},
		async getQuotationList() {
			try {
				this.pageLoading = true;
				const { quotations_analysis, quotations } = await getQuotationByProject(
					this.$route.params.uuid
				);
				this.quotation_analysis = quotations_analysis[0];
				console.log(this.quotation_analysis);
				this.quotationsList = quotations;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		this.internal = true;
		await this.getQuotationList();
	},
};
</script>
