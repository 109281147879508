<template>
	<Dialog
		v-if="dialogOpen"
		:dialog="dialogOpen"
		title="Asset Stocks"
		current
		v-on:close="dialogOpen = false"
	>
		<template v-slot:title>Proforma Title</template>
		<template v-slot:body>
			<table width="100%" class="detail-table scattered-table" style="font-size: 13px">
				<tr>
					<th width="150px">Title</th>
					<td>
						<ShowValue :object="singleProforma" object-key="title" label="Title"> </ShowValue>
					</td>
					<th width="150px">Amount</th>
					<td>
						<ShowPrice :object="singleProforma" object-key="amount" label="Amount"> </ShowPrice>
					</td>
				</tr>
				<tr>
					<th width="150px">Invoice Amount</th>
					<td>
						<ShowPrice :object="singleProforma" object-key="amount" label="Amount"> </ShowPrice>
					</td>
					<th width="150px">Status</th>
					<td>
						<Chip
							tooltip
							tooltip-text="Status"
							class="mr-4"
							:text="singleProforma.status"
							color="blue darken-4 white--text"
						></Chip>
					</td>
				</tr>
			</table>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close')"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog.vue";
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import Chip from "@/view/components/Chip.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "Proforma-detail-dialog",
	title: "Proforma Detail Dialog",
	mixins: [ListingMixin],
	components: { Dialog, ShowValue, ShowPrice, Chip },
	props: {
		dialogOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			singleProforma: {
				title: "Tittle",
				amount: 4560,
				invoice_amount: 4100,
				status: "accepted",
			},
		};
	},
	mounted() {
		this.pageLoading = false;
	},
};
</script>
