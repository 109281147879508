<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="detail-svg-icon d-flex align-center">
					<!--begin::Svg Icon-->
					<inline-svg
						class="mr-2"
						:src="$assetURL('media/custom-svg/profrom2.svg')"
						height="20"
						width="20"
					/>
					<!--end::Svg Icon-->
					<template v-if="title">
						<span>{{ title }}</span>
					</template>
					<template v-else>Proforma</template>
					<!-- <div  class="d-flex justify-space-between align-center">
						<div>
							<v-chip class="py-2 ml-2" color="orange" label outlined>Total Proforma: {{
								getProforma.length }}</v-chip>
						</div>
						<div class="mx-4">
							<v-chip class="py-2 ml-2" color="blue" label outlined>Total Draft: {{ totalDraftProforma
							}}</v-chip>
						</div>
						<div>
							<v-chip class="py-2 ml-2" color="green" label outlined>Total Accepted: {{
								totalAcceptedProforma
							}}</v-chip>
						</div>
						<div class="ml-4">
							<v-chip class="py-2 ml-2" color="cyan lighten-1" label outlined>Total Invoice: 10</v-chip>
						</div>
					</div> -->
				</div>

				<div class="d-flex ml-8 align-center justify-space-between">
					<AutoCompleteInput
						v-if="milestoneDropdown"
						hide-details
						:items="milestoneDropdown"
						class="w-80"
						id="milestone"
						placeholder="Milestone"
						item-text="phase"
						item-value="id"
						v-model="milestoneFilter.milestone"
					>
					</AutoCompleteInput>
					<DatePicker
						class="mx-2"
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						clearable
						id="date"
						v-model="milestoneFilter.date"
					>
					</DatePicker>
					<v-btn color="blue darken-4 white--text" class="mt-2" @click="initProforma()" tile depressed>
						Search</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 380px); min-height: 350px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Proforma No</th>
						<th>Title</th>
						<th width="150px">Proforma Amount</th>
						<th>Invoice Amount</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody v-if="getProforma.length">
					<tr v-for="(row, index) in getProforma" :key="index + 1">
						<td width="10px">{{ index + 1 }}</td>
						<td width="200px" class="text-capitalize truncate-text">
							<Chip tooltip tooltip-text="Proforma No" :text="row.barcode" color="cyan darken-4"> </Chip>
							<!-- <div class="mt-1">
								<v-chip small="small" color="cyan" label outlined>
									{{ row.status_value }}
								</v-chip>
							</div> -->
						</td>
						<td width="250px">
							<ShowValue :object="row" object-key="title" label="Title"></ShowValue>
						</td>
						<td width="250px">
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice :object="row" object-key="amount" label="Amount Raised"></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Accepted : </span>
								<ShowPrice :object="row" object-key="approved_amount" label="Collected Raised"> </ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="balance" label="Balance"></ShowPrice>
							</div>
						</td>
						<td width="250px">
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice
									:object="row"
									object-key="invoice_raised_amount"
									label="Amount Raised"
								></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Paid : </span>
								<ShowPrice :object="row" object-key="invoices_collected_amount" label="Collected Raised">
								</ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="invoice_balance" label="Pending Raised"></ShowPrice>
							</div>
						</td>
						<td width="250px">
							<Chip
								small="small"
								:color="getStatusColor(row.status_value)"
								tooltip
								tooltip-text="Status"
								label
								outlined
								:text="row.status_value"
							>
							</Chip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no Proformas at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<CreateProformaDialog
			:project="project"
			:addDialog="isProformaDialogOpen"
			:milestoneId="milestoneSNO"
			:currentProforma="currentProforma"
			@close="(isProformaDialogOpen = false), (milestoneSNO = null)"
			showMoreFields
			@load="initProforma()"
		/>

		<DeleteTemplate
			type="Proforma"
			v-on:success="deleteProforma"
			v-on:close="isDeleteDialogOpen = false"
			:delete-dialog="isDeleteDialogOpen"
			:deleteText="proformaSNO"
			:deleteByUrl="false"
		>
		</DeleteTemplate>

		<Dialog :dialog="rejectDialog" :dialog-width="600">
			<template v-slot:title> Reject Proforma</template>
			<template v-slot:body>
				<v-form ref="rejectForm" v-model.trim="formValid" lazy-validation>
					<v-row class="delete-dialog">
						<v-col md="12" class="py-0 red--text"> Are you sure want to reject Proforma </v-col>
						<v-col md="12" class="py-0">
							<TextAreaInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="remark"
								placeholder="Enter Remark"
								:rules="[vrules.required(rejectRemark, 'Remark')]"
								:class="{
									required: !rejectRemark,
								}"
								v-model="rejectRemark"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="rejectDialog = false">
					No, Close
				</v-btn>
				<slot name="extra-btn"></slot>
				<v-btn
					class="white--text"
					:loading="pageLoading"
					:disabled="pageLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="rejectProforma()"
				>
					Yes! Reject
				</v-btn>
			</template>
		</Dialog>

		<ApproveConfirmation
			v-if="approveDialog"
			type="Proforma"
			:updateStatus="false"
			v-on:success="approveProforma()"
			v-on:close="approveDialog = false"
			:approve-dialog="approveDialog"
			status="mark_as_approved"
			:uuid="proformaSNO"
			approve-type="quotation"
		>
		</ApproveConfirmation>

		<GenerateInvoice
			v-if="generatePerformsDialog"
			:dialog="generatePerformsDialog"
			:performId="performId"
			:project="project"
			@close="(generatePerformsDialog = false), (performId = null)"
		/>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue.vue";
import Dialog from "@/view/components/Dialog.vue";
import { mapGetters } from "vuex";
import ShowPrice from "@/view/components/ShowPrice.vue";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import CreateProformaDialog from "@/view/components/CreateProforma";
import ListingMixin from "@/core/mixins/listing.mixin";
import {
	GET_PROFORMA_BY_PROJECT,
	DELETE_PROFORMA,
	UPDATE_PROFORMA_STATUS,
} from "@/core/services/store/proforma.module.js";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import TextAreaInput from "@/view/components/TextAreaInput";
import GenerateInvoice from "@/view/components/GenerateInvoice";
import Chip from "@/view/components/Chip";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import DatePicker from "@/view/components/DatePicker";
// import { getMilestoneByProject } from "@/core/lib/project.lib";
export default {
	name: "proforma-listing",
	title: "Proforma Listing",
	mixins: [ListingMixin],
	components: {
		ShowValue,
		ShowPrice,
		Dialog,
		TextAreaInput,
		CreateProformaDialog,
		ApproveConfirmation,
		DeleteTemplate,
		Chip,
		AutoCompleteInput,
		GenerateInvoice,
		DatePicker,
	},
	data() {
		return {
			isProformaDialogOpen: false,
			proformaSNO: null,
			milestoneSNO: null,
			approveDialog: false,
			currentProforma: null,
			generatePerformsDialog: false,
			isDeleteDialogOpen: false,
			isMilestoneDetailDialogOpen: false,
			rejectDialog: false,
			uuid: null,
			rejectRemark: null,
			formValid: null,
			performId: null,
			milestoneFilter: {
				milestone: null,
				date: null,
			},
			milestoneList: [],
		};
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		project: {
			type: Object,
			default: () => {},
		},
		milestoneDropdown: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		async approveProforma() {
			this.pageLoading = true;
			const params = {
				action: "accepted",
				uuid: this.proformaSNO,
			};
			try {
				await this.$store.dispatch(UPDATE_PROFORMA_STATUS, params);
				this.approveDialog = false;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been approve." },
				]);
				await this.initProforma();
				this.proformaSNO = null;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async rejectProforma() {
			const formErrors = this.validateForm(this.$refs.rejectForm);
			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs.rejectForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			const params = {
				action: "rejected",
				uuid: this.proformaSNO,
				remark: this.rejectRemark,
			};
			try {
				await this.$store.dispatch(UPDATE_PROFORMA_STATUS, params);

				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been reject." },
				]);
				await this.initProforma();
				this.rejectDialog = false;
				this.proformaSNO = null;
				this.rejectRemark = null;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		openApproveDialog(proforma) {
			this.approveDialog = true;
			this.proformaSNO = proforma.uuid;
		},
		openDeleteDialog(proforma) {
			this.isDeleteDialogOpen = true;
			this.proformaSNO = proforma.uuid;
		},
		async deleteProforma() {
			this.pageLoading = true;
			try {
				await this.$store.dispatch(DELETE_PROFORMA, this.proformaSNO);
				this.pageLoading = false;
				this.isDeleteDialogOpen = false;
				this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Proforma has been deleted." },
				]);
				await this.initProforma();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			}
		},
		openRejectDialog(proforma) {
			this.rejectDialog = true;
			this.proformaSNO = proforma.uuid;
		},

		async initProforma() {
			this.pageLoading = true;
			try {
				const data = {
					uuid: this.$route.params.uuid,
					params: {
						filter_milestone: this.milestoneFilter.milestone,
						filter_date: this.milestoneFilter.date,
					},
				};
				await this.$store.dispatch(GET_PROFORMA_BY_PROJECT, data);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		// OpenEditDialog(row) {
		// 	console.log(row.project_milestone);
		// this.currentProforma = row;
		// this.isProformaDialogOpen = true;
		// this.uuid = row.uuid;
		// this.milestoneSNO = row.project_milestone;
		// this.$router.push({
		// 	name: "proforma-create",
		// 	params: { uuid: this.project.uuid },
		// 	query: { mileUUID: row.project_milestone, t: new Date().getTime(), lineitems: false, proformaUUID: row.uuid },
		// });
		// },
		createInvoice(proforma) {
			this.$router.push({
				name: "create-invoice",
				params: { uuid: this.project.uuid },
				query: { pfuuid: proforma.id, t: new Date().getTime() },
			});
			// this.performId = proforma.id;
			// this.generatePerformsDialog = true;
		},
	},
	computed: {
		...mapGetters(["getProforma", "errors"]),
		totalDraftProforma() {
			let total = 0;
			this.getProforma.forEach((item) => {
				if (item.status_value === "draft") {
					total = total + 1;
				}
			});
			return total;
		},
		totalAcceptedProforma() {
			let total = 0;
			this.getProforma.forEach((item) => {
				if (item.status_value === "accepted") {
					total = total + 1;
				}
			});
			return total;
		},
	},
	async mounted() {
		this.internal = true;
		await this.initProforma();
		// const { milestones } = await getMilestoneByProject(this.$route.params.uuid);
		// this.milestoneList = this.milestoneDropdown;
	},
};
</script>
