<template>
	<div>
		<div class="min-height-57px">
			<div class="font-level-3-bold my-auto d-flex justify-space-between w-100">
				<div class="mt-4">
					<div class="detail-svg-icon mr-2 d-flex">
						<!--begin::Svg Icon-->
						<inline-svg :src="$assetURL('media/custom-svg/Milestone.svg')" height="22" width="22" />
						<!--end::Svg Icon-->
						<span class="ml-3">Milestone</span>
					</div>
					<div class="d-flex flex-column my-2 ml-3 grey lighten-4" style="border-radius: 1rem">
						<div class="d-flex" style="border-radius: :1rem;width:100vw">
							<!-- <span class="font-level-3-bold"> Milestone </span> -->
							<div>
								<v-chip class="px-2 pr-2 py-1 white--text" color="indigo lighten-1" label outlined
									>Total Milestone Amount:
									<span
										class="mr-1"
										style="font-weight: 600"
										v-if="milestonesDetails?.total_milestone_amount"
									>
										{{ formatMoney(milestonesDetails?.total_milestone_amount) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
								<v-chip class="px-2 mx-4 pr-2 py-1 white--text" color="indigo lighten-1" label outlined
									>Total Milestone Balance:
									<span
										class="mr-1"
										style="font-weight: 600"
										v-if="milestonesDetails?.total_milestone_balance"
									>
										{{ formatMoney(milestonesDetails?.total_milestone_balance) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
						</div>
						<div class="d-flex my-2" style="border-radius: :1rem;width:100vw">
							<!-- <span class="font-level-3-bold"> Proforoma </span> -->
							<div class=" ">
								<v-chip class="px-2 py-1 white--text" color="light-blue darken-3" label outlined
									>Total Proforma:
									<span class="ml-1" v-if="milestonesDetails?.total_proforma_raised">
										{{ formatMoney(milestonesDetails?.total_proforma_raised) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
							<div class="mx-4">
								<v-chip class="px-2 py-1 white--text" outlined color="light-blue darken-3" label
									>Total Proforma Accepted:
									<span class="ml-1" v-if="milestonesDetails?.total_proforma_approved">
										{{ formatMoney(milestonesDetails?.total_proforma_approved) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
							<div class="">
								<v-chip class="px-2 py-1 white--text" color="light-blue darken-3" label outlined
									>Total Proforma Balance:
									<span class="ml-1" v-if="milestonesDetails?.total_proforma_balance">
										{{ formatMoney(milestonesDetails?.total_proforma_balance) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
						</div>
						<div class="d-flex" style="border-radius: :1rem;width:100vw">
							<div class="">
								<v-chip class="px-2 pr-2 py-1 white--text" color="cyan darken-1" label outlined
									>Total Invoice :
									<span class="ml-2" v-if="milestonesDetails?.total_invoice_raised">
										{{ formatMoney(milestonesDetails?.total_invoice_raised) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
							<div class="mx-4">
								<v-chip class="mx-2 px-2 pr-2 py-1 white--text" color="cyan darken-1" label outlined
									>Total Invoice Collected :
									<span class="ml-2" v-if="milestonesDetails?.total_invoice_collected">
										{{ formatMoney(milestonesDetails?.total_invoice_collected) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
							<div class="">
								<v-chip class="mx-2 px-2 pr-2 py-1 white--text" color="cyan darken-1" label outlined
									>Total Invoice Balance :
									<span class="ml-2" v-if="milestonesDetails?.total_invoice_balance">
										{{ formatMoney(milestonesDetails?.total_invoice_balance) }}
									</span>
									<span v-else>
										{{ formatMoney(0) }}
									</span>
								</v-chip>
							</div>
						</div>
					</div>
					<div class="ml-6 d-flex flex-wrap">
						<!-- <div class="mb-2"></div>
						<div class="mx-2 mb-2">
							<v-chip class="px-2 pr-2 py-1" color="blue lighten-2" label outlined
								>Total Proforma:
								<span v-if="milestonesDetails?.total_proforma_raised">
									{{ formatMoney(milestonesDetails?.total_proforma_raised) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div>
						<div class="mb-2">
							<v-chip class="px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Proforma Accepted:
								<span v-if="milestonesDetails?.total_proforma_approved">
									{{ formatMoney(milestonesDetails?.total_proforma_approved) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div>
						<div class="mx-2 mb-2">
							<v-chip class="px-2 pr-2 py-1" color="deep-orange darken-1" label outlined
								>Total Proforma Balance:
								<span v-if="milestonesDetails?.total_proforma_balance">
									{{ formatMoney(milestonesDetails?.total_proforma_balance) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div> -->
						<!-- <div class="mb-2">
							<v-chip class="px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Invoice :
								<span v-if="milestonesDetails?.total_invoice_raised">
									{{ formatMoney(milestonesDetails?.total_invoice_raised) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div>
						<div class="mb-2">
							<v-chip class="mx-2 px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Invoice Collected :
								<span v-if="milestonesDetails?.total_invoice_collected">
									{{ formatMoney(milestonesDetails?.total_invoice_collected) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div>
						<div class="mb-2">
							<v-chip class="mx-2 px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Invoice Balance :
								<span v-if="milestonesDetails?.total_invoice_balance">
									{{ formatMoney(milestonesDetails?.total_invoice_balance) }}
								</span>
								<span v-else>
									{{ formatMoney(0) }}
								</span>
							</v-chip>
						</div> -->
						<!-- <div class="mb-2">
							<v-chip class="mx-2 px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Deliverable:
								<span> 0 </span>
							</v-chip>
						</div>
						<div class="mb-2">
							<v-chip class="mx-2 px-2 pr-2 py-1" color="light-blue darken-3" label outlined
								>Total Delivered:
								<span> 0 </span>
							</v-chip>
						</div> -->
						<!-- <div >
							<v-chip class="mx-2 px-2 pr-2  py-1" color="light-blue darken-3" label outlined>Total
								deliverable:
								<span>
									{{0 }}
								</span>
							</v-chip>
						</div>
						<div >
							<v-chip class="mx-2 px-2 pr-2  py-1" color="light-blue darken-3" label outlined>Total
								delivered:
								<span>
									{{0 }}
								</span>
						
							</v-chip>
						</div>
						<div >
							<v-chip class="mx-2 px-2 pr-2  py-1" color="teal accent-3" label outlined>Total
								returned:
								<span>
									{{0 }}
								</span>
							</v-chip>
						</div> -->
					</div>
				</div>
			</div>
			<div class="mb-3 d-flex justify-end">
				<v-btn
					@click="initMilestones()"
					color="blue darken-4 white--text"
					class="ml-auto"
					tile
					depressed
				>
					<v-icon>mdi-reload</v-icon>
				</v-btn>
				<v-btn
					v-if="getPermission('project-milestone::create')"
					@click="openCreateMilestoneDialog(false)"
					color="blue darken-4 white--text"
					class="ml-4"
					tile
					depressed
				>
					<v-icon>mdi-plus</v-icon> Create</v-btn
				>
			</div>
		</div>

		<div
			v-if="!pageLoading"
			class="overflow-y taskListing"
			style="max-height: calc(100vh - 430px); min-height: 350px"
		>
			<v-simple-table class="bt-table listing-table table-head-sticky" width="100%">
				<thead>
					<tr>
						<th width="10px">#</th>
						<th>Name</th>
						<th width="200px">Description</th>
						<th>Date</th>
						<!-- <th>Reminder Date</th> -->
						<th>Milestone</th>
						<th>Proforma</th>
						<th>Invoice</th>
						<!-- <th>Invoice Date</th> -->
						<!-- <th>Invoice Raised</th> -->
						<!-- <th>Payment Received</th> -->
						<!-- <th>Remaining</th> -->
						<!-- <th>Attachment</th> -->
						<th>Actions</th>
					</tr>
				</thead>
				<tbody v-if="milestones.length">
					<tr
						class="task_row cursor-pointer"
						v-for="(row, index) in milestones"
						:key="index + 1"
						@click="openMilestoneDetailDialog(row)"
					>
						<td width="10px">{{ index + 1 }}</td>
						<td>
							<ShowValue :object="row" object-key="phase" label="Name"></ShowValue>
						</td>
						<td width="200px">
							<span class="line-clamp-2" style="max-width: 200px">
								<ShowTextAreaValue :object="row" object-key="description" label="Description">
								</ShowTextAreaValue>
							</span>
						</td>
						<td>
							<!-- <span v-if="row.date">
								{{ formatDate(row.date) }}
							</span>
							<em class="text-muted" v-else> no date </em> -->
							<div>
								<span class="fw-500">Date :</span>
								<template v-if="row.date">
									{{ formatDate(row.date) }}
								</template>
								<template v-else>
									<em class="text-muted">no date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">Reminder Date :</span>
								<template v-if="row.reminder_date">
									{{ formatDate(row.reminder_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no reminder date</em>
								</template>
							</div>
							<!-- <div>
								<span v-if="row.reminder_date"> ({{ formatDate(row.reminder_date) }}) </span>
								<em class="text-muted" v-else>( no reminder date )</em>
							</div> -->
						</td>
						<!-- <td>
						</td> -->
						<td>
							<div>
								<span class="fw-500 mr-1">Amount :</span>
								<ShowPrice :object="row" object-key="amount" label="Payment Amount"></ShowPrice>
							</div>
							<div>
								<span class="fw-500 mr-1">Progress:</span>
								<span v-if="row.progress"> {{ row.progress }}% </span>
								<em v-else class="text-muted fw-400">No progress</em>
							</div>
						</td>
						<td width="250px">
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice :object="row" object-key="performa_raised_amount" label="Raised Amount">
								</ShowPrice>
							</div>
							<div>
								<span class="fw-500">Accepted : </span>
								<ShowPrice :object="row" object-key="performa_approved_amount" label="Approved Raised">
								</ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="performa_balance" label="Balance Amount"> </ShowPrice>
							</div>
						</td>
						<td width="250px">
							<div>
								<span class="fw-500">Raised : </span>
								<ShowPrice :object="row" object-key="invoice_raised_amount" label="Invoice Amount">
								</ShowPrice>
							</div>
							<div>
								<span class="fw-500">Paid : </span>
								<ShowPrice :object="row" object-key="invoice_collected_amount" label="Collected Amount">
								</ShowPrice>
							</div>
							<div>
								<span class="fw-500">Balance : </span>
								<ShowPrice :object="row" object-key="invoice_balance" label="Balance"></ShowPrice>
							</div>
						</td>

						<td class="simple-table-td">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										:disabled="pageLoading"
										v-if="getPermission('project-milestone::edit')"
										v-bind="attrs"
										v-on="on"
										@click.prevent.stop="openCreateMilestoneDialog(true, row)"
										class="mx-2"
										dark
										color="blue darken-4"
										fab
										x-small
									>
										<v-icon>mdi-pencil</v-icon></v-btn
									>
								</template>
								<span>Update Milestone</span>
							</v-tooltip>

							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-btn
											@click.stop.prevent="openDeleteConfirmationDialog(row)"
											class="mx-2"
											:disabled="row.disable_delete == 1 ? true : false || pageLoading"
											v-if="getPermission('project-milestone::delete')"
											:dark="row.disable_delete != 1 ? true : false || pageLoading"
											color="red lighten-1'"
											fab
											x-small
										>
											<v-icon small dark>mdi-trash-can-outline</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Delete Milestone</span>
							</v-tooltip>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no milestone at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog
			:dialog="isMilestoneDetailDialogOpen"
			title="Milestone Detail"
			current
			v-on:close="isMilestoneDetailDialogOpen = false"
		>
			<template v-slot:title> Milestone Detail </template>
			<template v-slot:body>
				<table width="100%" class="detail-table scattered-table">
					<tr>
						<th width="150px">Description</th>
						<td>
							<ShowValue :object="milestoneDetail" object-key="description" label="Milestone Description">
							</ShowValue>
						</td>
						<th width="150px">Date</th>
						<td>
							<span v-if="milestoneDetail && milestoneDetail.date" class="fw-500 no-wrap">
								{{ formatDate(milestoneDetail.date) }}
							</span>
							<em class="text-muted" v-else>no date</em>
						</td>
					</tr>
					<tr>
						<th width="150px">Reminder Date</th>
						<td>
							<span v-if="milestoneDetail && milestoneDetail.reminder_date">
								{{ formatDate(milestoneDetail.reminder_date) }}
							</span>
							<em class="text-muted" v-else> no reminder date </em>
						</td>
						<th width="150px">Payment Amount</th>
						<td>
							<ShowPrice :object="milestoneDetail" object-key="amount" label="Payment Amount"> </ShowPrice>
						</td>
					</tr>
					<tr>
						<th width="150px">Percentage</th>
						<td>
							<ShowValue :object="milestoneDetail" object-key="progress" label="Percentage">%</ShowValue>
						</td>
						<th width="150px">Amount Raised</th>
						<td>
							<ShowPrice :object="milestoneDetail" object-key="amount_raised" label="Amount Raised">
							</ShowPrice>
						</td>
					</tr>
					<tr>
						<th width="150px">Invoice Date</th>
						<td>
							<span v-if="milestoneDetail && milestoneDetail.invoice_date">
								{{ formatDate(milestoneDetail.invoice_date) }}
							</span>
							<em class="text-muted" v-else> no invoice date </em>
						</td>
						<th width="150px">Invoice Raised</th>
						<td>
							<ShowValue :object="milestoneDetail" object-key="invoice_raised" label="Invoice Raised">
							</ShowValue>
						</td>
					</tr>
					<tr>
						<th width="150px">Payment Received</th>
						<td>
							<ShowValue :object="milestoneDetail" object-key="payment_received" label="Payment Received">
							</ShowValue>
						</td>
						<th width="150px">Remaining</th>
						<td>
							<ShowValue :object="milestoneDetail" object-key="payment_remaining" label="Remaining">
							</ShowValue>
						</td>
					</tr>
				</table>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="isMilestoneDetailDialogOpen = false">
					Close
				</v-btn>
				<!-- <v-btn @click="saveMilestone" class="white--text" depressed color="green" tile> Save </v-btn> -->
			</template>
		</Dialog>

		<CreateProformaDialog
			:project="project"
			:addDialog="isProformaDialogOpen"
			@close="(isProformaDialogOpen = false), (milestoneSNO = null)"
			:milestoneId="milestoneSNO"
			@load="initMilestones()"
		/>
		<Dialog
			:dialog="isCreateDialogOpen"
			title="Asset Stocks"
			current
			v-on:close="isCreateDialogOpen = false"
		>
			<template v-slot:title> Create Milestone </template>
			<template v-slot:body>
				<div class="min-height-280">
					<v-form
						v-if="isCreateDialogOpen"
						ref="milestoneForm"
						v-on:submit.stop.prevent="updateOrCreate()"
						lazy-validation
						v-model.trim="formValid"
					>
						<v-row>
							<v-col md="3">
								<label for="name" class="btx-label mt-5">Name</label>
							</v-col>
							<v-col md="9">
								<TextInput
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="name"
									placeholder="Name"
									v-model="milestone.phase"
								>
								</TextInput>
							</v-col>
							<v-col md="3">
								<label for="description" class="btx-label mt-5">Description</label>
							</v-col>
							<v-col md="9">
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="description"
									placeholder="Description"
									v-model="milestone.description"
								>
								</TextAreaInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4 required">Date</label>
							</v-col>
							<v-col md="3">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="milestone_date"
									v-model="milestone.date"
									:rules="[vrules.required(milestone.date, 'Date')]"
									:class="{
										required: !milestone.date,
									}"
								></DatePicker>
							</v-col>
							<v-col class="text-right" md="3">
								<label for="team" class="btx-label mt-4">Reminder Date</label>
							</v-col>
							<v-col md="3">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="task_start_date"
									v-model="milestone.reminder_date"
								></DatePicker>
							</v-col>

							<v-col md="3">
								<label for="team" class="btx-label mt-4 required">Payment Amount</label>
							</v-col>
							<v-col md="4" class="d-flex justify-center align-center">
								<TextInput
									hide-details
									type="number"
									placeholder="Amount"
									:rules="[vrules.required(milestone.payment_amount, 'Payment Amount')]"
									:class="{
										required: !milestone.payment_amount,
									}"
									v-model="milestone.payment_amount"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</TextInput>
								<div class="ml-2 mt-4 font-level-0 d-flex justify-center align-center">
									<TooltipQuestion>
										<template v-slot:text>Budget of project</template>
									</TooltipQuestion>
								</div>
							</v-col>
							<v-col class="text-right" md="2">
								<label for="team" class="btx-label mt-4 required">Percentage</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									placeholder="Percentage"
									vMask="###"
									v-model="milestone.percentage"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[
										vrules.required(milestone.percentage, 'Percentage'),
										vrules.lessThan(milestone.percentage, 'Percentage', 100),
									]"
									:class="{ required: !milestone.percentage }"
								>
								</TextInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-4">Attachment</label>
							</v-col>
							<v-col md="9">
								<FileUpload v-model="milestone.attachment"></FileUpload>
							</v-col>
							<template v-if="isAddMoreFields">
								<v-col md="3">
									<label for="team" class="btx-label mt-4">Amount Raised</label>
								</v-col>
								<v-col md="3">
									<TextInput
										hide-details
										type="number"
										placeholder="Amount Raised"
										v-model.number="milestone.amount_raised"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
									</TextInput>
								</v-col>
								<v-col md="3">
									<label for="team" class="btx-label mt-4">Invoice Date</label>
								</v-col>
								<v-col md="3">
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="task_start_date"
										v-model="milestone.invoice_date"
									></DatePicker>
								</v-col>
								<v-col md="3">
									<label for="team" class="btx-label mt-4">Invoice Raised</label>
								</v-col>
								<v-col md="3">
									<TextInput
										hide-details
										type="number"
										placeholder="Invoice Raised"
										v-model.number="milestone.invoice_raised"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
									</TextInput>
								</v-col>
								<v-col md="3">
									<label for="team" class="btx-label mt-4">Payment Received</label>
								</v-col>
								<v-col md="3">
									<TextInput
										hide-details
										type="number"
										placeholder="Payment Received"
										v-model.number="milestone.payment_received"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
									</TextInput>
								</v-col>
								<v-col md="3">
									<label for="team" class="btx-label mt-4">Remaining</label>
								</v-col>
								<v-col md="3">
									<TextInput
										hide-details
										type="number"
										placeholder="Amount"
										v-model.number="milestone.payment_remaining"
										:disabled="pageLoading"
										:loading="pageLoading"
									></TextInput>
								</v-col>
							</template>
						</v-row>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="closedCreateMilestoneDialog">
					Close
				</v-btn>
				<v-btn @click="updateOrCreate()" class="white--text" depressed color="blue darken-4" tile>
					Save
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Milestone</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">Milestone</span>
							<span class="red--text font-bolder lighten-1"> {{ deleteText }} </span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" @click="deleteMilestone" depressed color="red lighten-1" tile>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile @click="deleteDialog = false"> No, Close </v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="openCheckDialog" :dialog-width="640">
			<template v-slot:title>Approve Milestone</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">Milestone</span>
							<span class="red--text font-bolder lighten-1"> {{ deleteMilestoneSNO }} </span>
							will be approve forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" @click="approveMilestone" depressed color="green" tile>
					Yes! Approve
				</v-btn>
				<v-btn depressed tile @click="openCheckDialog = false"> No, Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ShowValue from "@/view/components/ShowValue.vue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
import TextInput from "@/view/components/TextInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import Dialog from "@/view/components/Dialog";
import CreateProformaDialog from "@/view/components/CreateProforma";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import {
	CreateMilestone,
	updateMilestone,
	getMilestoneByProject,
	deleteMilestone,
	updateMilestoneStatus,
} from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "milestone-listing",
	title: "Milestone Listing",
	mixins: [ListingMixin],
	components: {
		Dialog,
		ShowTextAreaValue,
		ShowValue,
		ShowPrice,
		TextInput,
		TextAreaInput,
		DatePicker,
		CreateProformaDialog,
		FileUpload,
		TooltipQuestion,
	},
	data() {
		return {
			isAddMoreFields: false,
			milestonesDetails: null,
			deleteDialog: false,
			pageLoading: false,
			milestonesSNO: null,
			openCheckDialog: false,
			isMilestoneDetailDialogOpen: false,
			isCreateDialogOpen: false,
			milestoneDetail: null,
			isProformaDialogOpen: false,
			uuid: null,
			deleteText: null,
			deleteUUID: null,
			deleteMilestoneSNO: null,
			milestones: [],
			milestoneSNO: null,
			total_milestone_amount: null,
			formValid: true,
			milestone: {
				date: null,
				phase: null,
				attachment: null,
				percentage: null,
				description: null,
				invoice_date: null,
				reminder_date: null,
				amount_raised: null,
				payment_amount: null,
				invoice_raised: null,
				payment_received: null,
				payment_remaining: null,
			},
			proforma: {
				title: null,
				amount: null,
				attachment: [],
			},
		};
	},
	props: {
		// relationType: {
		// 	type: String,
		// 	default: null,
		// },
		type: {
			type: String,
			default: null,
		},

		// status: {
		// 	type: String,
		// 	default: null,
		// },
		project: {
			type: Object,
			default: () => {},
		},
		// relationTypeId: {
		// 	type: Number,
		// 	default: 0,
		// },
	},
	methods: {
		openDeleteConfirmationDialog(row) {
			let _this = this;
			_this.deleteDialog = true;
			_this.deleteUUID = row.uuid;
			_this.deleteText = row.phase;
			_this.deleteMilestoneSNO = row.id;
		},
		async deleteMilestone() {
			let _this = this;
			try {
				const params = {
					project: this.project.id,
					milestones: [this.deleteUUID],
				};
				await deleteMilestone(params);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Milestone has been deleted." },
				]);
				this.initMilestones();
				this.deleteDialog = false;
				this.deleteUUID = null;
				this.deleteMilestoneSNO = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		openCheckConfirmationDialog(milestone) {
			this.uuid = milestone.uuid;
			this.openCheckDialog = true;
		},
		async approveMilestone() {
			const _this = this;
			_this.pageLoading = true;
			const params = {
				action: "completed",
				selected: [this.uuid],
			};
			this.openCheckDialog = false;
			try {
				await updateMilestoneStatus(params);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Milestone has been Approve." },
				]);
				await _this.initMilestones();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
				_this.uuid = null;
			}
		},
		openCreateMilestoneDialog(addMoreFields, milestone) {
			// this.isCreateDialogOpen = true;
			// if (addMoreFields) {
			// 	(this.milestone = {
			// 		editable: true,
			// 		phase: milestone.phase,
			// 		percentage: milestone.progress,
			// 		description: milestone.description,
			// 		date: milestone.date,
			// 		reminder_date: milestone.reminder_date,
			// 		payment_amount: milestone.amount,
			// 		payment_received: milestone.payment_received,
			// 		payment_remaining: milestone.remaining,
			// 		amount_raised: milestone.amount_raised,
			// 		invoice_date: milestone.invoice_date,
			// 		invoice_raised: milestone.invoice_raised,
			// 	}),
			// 		(this.isAddMoreFields = true);
			// 	this.uuid = milestone.uuid;
			// } else {
			// 	this.isAddMoreFields = false;
			// }
			// this.$router.push('/project/' + this.project.uuid + '/milestone/create/' +milestone.uuid);

			if (addMoreFields) {
				this.$router.push({
					name: "milestone-create",
					params: { uuid: this.project.uuid },
					query: { mileUUID: milestone.uuid, t: new Date().getTime() },
				});
			} else {
				this.$router.push({
					name: "milestone-create",
					params: { uuid: this.project.uuid },
					query: { t: new Date().getTime() },
				});
			}
		},
		closedCreateMilestoneDialog() {
			this.isCreateDialogOpen = false;
		},
		openMilestoneDetailDialog(milestone) {
			this.$router.push({
				name: "milestone-detail",
				params: { uuid: this.project.uuid },
				query: { mileUUID: milestone.uuid, t: new Date().getTime() },
			});
		},
		openGenerateProformaDialog(milestone) {
			this.$router.push({
				name: "proforma-create",
				params: { uuid: this.project.uuid },
				query: { mileUUID: milestone.uuid, t: new Date().getTime(), lineitems: false },
			});
			// this.isProformaDialogOpen = true;
			// this.uuid = milestone.uuid;
			// this.milestoneSNO = milestone.id;
		},

		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.milestoneForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.milestoneForm.validate()) {
				return false;
			}
			try {
				const formData = {
					project: _this.project.id,
					phase: _this.milestone.phase,
					date: _this.milestone.date,
					description: _this.milestone.description,
					amount: _this.milestone.payment_amount,
					progress: _this.milestone.percentage,
					reminder_date: _this.milestone.reminder_date,
					files: _this.milestone.attachment,
					amount_raised: _this.milestone.amount_raised,
					invoice_date: _this.milestone.invoice_date,
					invoice_raised: _this.milestone.invoice_raised,
					payment_received: _this.milestone.payment_received,
					remaining: _this.milestone.payment_remaining,
				};
				_this.pageLoading = true;
				if (_this.uuid) {
					await updateMilestone(_this.uuid, formData);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been update." },
					]);
					_this.uuid = null;
				} else {
					await CreateMilestone(formData);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Milestone has been created." },
					]);
				}
				_this.milestone = {
					project: null,
					phase: null,
					date: null,
					description: null,
					amount: null,
					progress: null,
					reminder_date: null,
					files: null,
					amount_raised: null,
					invoice_raised: null,
					payment_received: null,
					remaining: null,
				};
				await _this.initMilestones();
				_this.isCreateDialogOpen = false;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async initMilestones() {
			this.pageLoading = true;
			try {
				// const { milestones, total_milestone_amount } = await getMilestoneByProject(
				// 	this.$route.params.uuid
				// );
				const data = await getMilestoneByProject(this.$route.params.uuid);
				this.milestonesDetails = data;
				this.milestones = this.milestonesDetails.milestones;
				// this.milestones = milestones;
				// this.total_milestone_amount = total_milestone_amount;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		this.internal = true;
		await this.initMilestones();
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
